<template>
    <div>
        <el-breadcrumb style="margin:0 0 6px 6px;" separator="/">
            <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
            <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            <el-breadcrumb-item>日志列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="content">
            <div class="list_box">
                <div class="tt1">日志列表</div>
            </div>
            <template>
                <el-table
                    :header-cell-style="tableHeader"
                    :data="indexList"
                    style="width: 100%"
                    stripe
                >
                    <el-table-column width="60px" prop="id" label="ID号"></el-table-column>
                    <el-table-column prop="username" label="用户名"></el-table-column>
                    <el-table-column min-width="140px" prop="title" label="标题"></el-table-column>
                    <el-table-column min-width="140px" prop="url" label="Url"></el-table-column>
                    <el-table-column min-width="100px" prop="ip" label="IP"></el-table-column>
                    <el-table-column prop="useragent" label="Browser">
                        <template v-slot="scope">
                            <div>{{scope.row.useragent.slice(0, 11)}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="130px"
                       
                        prop="createtime"
                        label="创建时间"
                    >
                        <template v-slot="scope">{{scope.row.createtime | dateFormat}}</template>
                    </el-table-column>
                    <el-table-column width="130px" label="操作">
                        <template v-slot="scope">
                            <el-tooltip
                                :enterable="false"
                                effect="dark"
                                content="查看"
                                placement="top-end"
                            >
                                <el-button
                                    size="mini"
                                    type="primary"
                                    icon="iconfont icon-chakan-copy"
                                    @click="chakan(scope.row)"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip
                                :enterable="false"
                                effect="dark"
                                content="删除"
                                placement="top-end"
                            >
                                <el-button
                                    size="mini"
                                    type="danger"
                                    icon="iconfont icon-shanchu"
                                    @click="del(scope.row.id)"
                                ></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 15, 30, 50]"
                    :page-size="limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </div>
            <el-dialog
                title="详情"
                :close-on-click-modal="false"
                :visible.sync="dialogVisible"
                width="1000px"
                :before-close="handleClose"
            >
                <div class="xian2"></div>
                <el-form disabled ref="form" :model="addform" label-width="120px">
                    <el-form-item label="id：">
                        <el-input size="small" style="width:450px;" v-model="addform.id" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="admin_id：">
                        <el-input size="small" style="width:450px;" v-model="addform.admin_id"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名：">
                        <el-input size="small" style="width:450px;" v-model="addform.username"></el-input>
                    </el-form-item>
                    <el-form-item label="url：">
                        <el-input size="small" style="width:450px;" v-model="addform.url"></el-input>
                    </el-form-item>
                    <el-form-item label="标题：">
                        <el-input size="small" style="width:450px;" v-model="addform.title"></el-input>
                    </el-form-item>
                    <el-form-item label="内容：">
                        <el-input size="small" type="textarea" style="width:450px;" v-model="addform.content"></el-input>
                    </el-form-item>
                    <el-form-item label="ip：">
                        <el-input size="small" style="width:450px;" v-model="addform.ip"></el-input>
                    </el-form-item>
                    <el-form-item label="useragent：">
                        <el-input size="small" type="textarea" style="width:450px;" v-model="addform.useragent"></el-input>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333'
      },
      dialogVisible: false,
      addform: {
        id: '',
        admin_id: '',
        username: '',
        url: '',
        title: '',
        content: '',
        ip: '',
        useragent: ''
      }
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.dialogVisible = false

      this.addform = {
        id: '',
        admin_id: '',
        username: '',
        url: '',
        title: '',
        content: '',
        ip: '',
        useragent: ''
      }
    },

    // 管理员日志列表
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/auth/adminlog/index',
        {
          params: {
            page: this.page,
            limit: this.limit
          }
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },

    // 查询
    chaxun() {
      this.page = 1
      this.warehousing()
    },
    // 详情
    chakan(row) {
      this.dialogVisible = true
      this.addform = {
        id: row.id,
        admin_id: row.admin_id,
        username: row.username,
        url: row.url,
        title: row.title,
        content: row.content,
        ip: row.ip,
        useragent: row.useragent
      }
    },
    // 删除
    del(id) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            'edulogin.php/auth/adminlog/delete',
            {
              params: {
                ids: id
              }
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.index()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    padding: 15px 1px;
  }
  .int_width {
    width: 242px;
    margin-right: 100px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
}
</style>
