<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>人员管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">角色列表</div>
        <div>
          <!-- <el-button size="mini" type="cha" icon="el-icon-search" @click="chaxun">查询</el-button> -->
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <!-- <div class="int_box">
        <span>ID号：</span>
        <el-input size="small" class="int_width" placeholder="请输入ID号" v-model="name" clearable></el-input>
        <span>父级ID号：</span>
        <el-input size="small" class="int_width" placeholder="请输入父级ID号" v-model="name" clearable></el-input>
        
      </div>
      <div class="int_box1">
        <span>角色：</span>
        <el-input size="small" class="int_width" placeholder="请输入角色" v-model="name" clearable></el-input>
      </div>-->
      <template>
        <el-table
          default-expand-all
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          row-key="id"
          stripe
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="id" label="ID号"></el-table-column>
          <el-table-column prop="pid" label="父级ID"></el-table-column>
          <el-table-column prop="name" label="角色名称"></el-table-column>
          <el-table-column
            :formatter="dateFormat"
            prop="createtime"
            label="添加时间"
          ></el-table-column>
          <el-table-column width="130px" label="操作">
            <template v-slot="scope">
              <el-tooltip
                v-if="scope.$index !== 0"
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.pid !== 0"
                effect="dark"
                content="删除"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="danger"
                  icon="iconfont icon-shanchu"
                  @click="del(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[8, 10, 15, 20]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div> -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="height: 500px; overflow-y: scroll">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="名称：" prop="name">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.name"
                placeholder="请输入名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="父级：" prop="pid">
              <el-cascader
                size="small"
                clearable
                style="width: 450px"
                @change="cascaderChange"
                v-model="addform.pid"
                :props="props"
                :options="indexList"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="权限：" prop="rules">
              <el-tree
                ref="tree"
                :data="labelList"
                show-checkbox
                check-strictly
                node-key="id"
                :default-expanded-keys="getMenuIdsList"
                :default-checked-keys="getMenuIdsList"
                :props="roleProps"
              ></el-tree>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
              <el-radio-group v-model="addform.status">
                <el-radio :label="'normal'">正常</el-radio>
                <el-radio :label="'hidden'">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn"
          >确定</el-button
        >
        <el-button v-else type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 8,

      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        name: '',
        pid: '',
        status: 'normal',
        rules: '',
      },

      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        pid: [{ required: true, message: '请选择父级', trigger: 'blur' }],
        rules: [{ required: true, message: '请选择权限', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
      },
      // 树节点
      labelList: [],
      roleProps: {
        children: 'children',
        label: 'text',
      },
      getMenuIdsList: [],
      id: '',
      text: '',
      }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        name: '',
        pid: '',
        status: 'normal',
        rules: '',
        id: '',
      }
      this.id = ''
      this.getMenuIdsList = []
      this.index()
    },

    dateFormat(row) {
      var date = new Date(row.createtime * 1000)
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D + h + m + s
      console.log(dateFormat(1533293827000))
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 角色列表
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/auth/group/index'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
      this.addform.pid = res.data.list[0].id
      this.roletree(res.data.list[0].id)
    },

    // 查询
    // chaxun() {
    //   this.index()
    // },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.__token__()
    },
    // 级联选择器变化事件
    cascaderChange(value) {
      console.log(value)
      if (value) {
        this.roletree(value)
      } else {
        this.labelList = []
      }
    },
    // 读取角色权限
    async roletree(pid) {
      const { data: res } = await this.$http.get(
        'edulogin.php/auth/group/roletree',
        {
          params: {
            pid: pid,
            id: this.id,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.labelList = res.data
    },
    // 添加
    add_btn() {
      this.addform.rules = this.$refs.tree.getCheckedKeys().join()
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        this.addform.__token__ = this.token

        const { data: res } = await this.$http.post(
          'edulogin.php/auth/group/add',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.addform = {
          name: '',
          pid: '',
          status: 'normal',
          rules: '',
        }
        this.getMenuIdsList = []
        this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.text = '编辑'
      this.id = row.id
      console.log(row)
      this.dialogVisible = true
      this.__token__()
      this.roletree(row.pid)
      this.getMenuIdsList = row.rules.split(',').map(Number)
      console.log(this.getMenuIdsList)
      this.addform = {
        name: row.name,
        pid: row.pid,
        status: row.status,
        rules: row.rules,
        id: row.id,
      }
    },
    // 确认编辑
    edit_btn() {
      this.addform.rules = this.$refs.tree.getCheckedKeys().join()
      console.log(this.$refs.tree.getCheckedKeys().join())
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        this.addform.__token__ = this.token

        const { data: res } = await this.$http.post(
          'edulogin.php/auth/group/edit',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.id = ''
        this.addform = {
          name: '',
          pid: '',
          status: 'normal',
          rules: '',
          id: '',
        }
        this.getMenuIdsList = []
        this.index()
      })
    },
    // 删除
    del(id) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post(
            'edulogin.php/auth/group/del',
            {
              ids: id,
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.index()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    padding: 15px 1px;
  }
  .int_width {
    width: 242px;
    margin-right: 100px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
}
</style>
