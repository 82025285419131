<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>人员管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">权限列表</div>
        <div>
          <!-- <el-button size="mini" type="cha" icon="el-icon-search" @click="chaxun">查询</el-button> -->
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <!-- <div class="int_box">
        <span>ID号：</span>
        <el-input size="small" class="int_width" placeholder="请输入ID号" v-model="name" clearable></el-input>
        <span>父级ID号：</span>
        <el-input size="small" class="int_width" placeholder="请输入父级ID号" v-model="name" clearable></el-input>
        
      </div>
      <div class="int_box1">
        <span>角色：</span>
        <el-input size="small" class="int_width" placeholder="请输入角色" v-model="name" clearable></el-input>
      </div>-->
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          row-key="id"
          stripe
          :tree-props="{ children: 'children' }"
        >
          <el-table-column label="ID号">
            <template v-slot="scope">
              <i v-if="scope.row.pid == 0" class="el-icon-circle-plus"></i>
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="pid" label="父级ID"></el-table-column>
          <el-table-column prop="name" label="路径"></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column label="状态">
            <template v-slot="scope">
              <span>{{ scope.row.status === 'normal' ? '正常' : '隐藏' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否菜单">
            <template v-slot="scope">
              <span>{{ scope.row.ismenu === 0 ? '否' : '是' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="icon" label="图标"></el-table-column>
          <el-table-column width="130px" label="操作">
            <template v-slot="scope">
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-end">
                <el-button
                  size="mini"
                  type="danger"
                  icon="iconfont icon-shanchu"
                  @click="del(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[8, 10, 15, 20]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div> -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="height: 500px; overflow-y: scroll">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="父级：">
              <el-cascader
                size="small"
                style="width: 450px"
                clearable
                v-model="addform.pid"
                :props="props"
                :options="indexList"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="规则路径：" prop="name">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.name"
                placeholder="请输入规则路径"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="标题：" prop="title">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.title"
                placeholder="请输入标题"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="图标：" prop="icon">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.icon"
                placeholder="请输入图标"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="权重：" prop="weigh">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.weigh"
                placeholder="请输入权重"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
              <el-radio-group v-model="addform.status">
                <el-radio :label="'normal'">正常</el-radio>
                <el-radio :label="'hidden'">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否菜单：" prop="ismenu">
              <el-radio-group v-model="addform.ismenu">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'0'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input
                size="small"
                type="textarea"
                style="width: 450px"
                v-model="addform.remark"
                placeholder="请输入备注"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn"
          >确定</el-button
        >
        <el-button v-else type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 8,

      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        pid: '0',
        name: '',
        title: '',
        icon: '',
        weigh: '0',
        status: 'normal',
        ismenu: '1',
        remark: '',
      },

      rules: {
        name: [{ required: true, message: '请输入规则路径', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入图标', trigger: 'blur' }],
        weigh: [{ required: true, message: '请输入权重', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        ismenu: [
          { required: true, message: '请选择是否菜单', trigger: 'blur' },
        ],
      },
      props: {
        value: 'id',
        label: 'title',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
      },
      id: '',
      text: '',
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        pid: '0',
        name: '',
        title: '',
        icon: '',
        weigh: '0',
        status: 'normal',
        ismenu: '1',
        remark: '',
        id: '',
      }
      this.id = ''
    },

    dateFormat(row) {
      var date = new Date(row.createtime * 1000)
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D + h + m + s
      console.log(dateFormat(1533293827000))
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 权限列表
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/auth/rule/index'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.rows
      this.total = res.data.total
    },

    // 查询
    // chaxun() {
    //   this.index()
    // },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.__token__()
    },

    // 添加
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/auth/rule/add',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.addform = {
          pid: '0',
          name: '',
          title: '',
          icon: '',
          weigh: '0',
          status: 'normal',
          ismenu: '1',
          remark: '',
        }
        this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.text = '编辑'
      this.id = row.id
      console.log(row)
      this.dialogVisible = true
      this.__token__()
      this.addform = {
        pid: row.pid,
        name: row.name,
        title: row.title,
        icon: row.icon,
        weigh: row.weigh,
        status: row.status,
        ismenu: row.ismenu + '',
        remark: row.remark,
        id: row.id,
      }
    },
    // 确认编辑
    edit_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        this.addform.__token__ = this.token

        const { data: res } = await this.$http.post(
          'edulogin.php/auth/rule/edit',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.id = ''
        this.addform = {
          pid: '0',
          name: '',
          title: '',
          icon: '',
          weigh: '0',
          status: 'normal',
          ismenu: '1',
          remark: '',
          id: '',
        }
        this.index()
      })
    },
    // 删除
    del(id) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            'edulogin.php/auth/rule/del',
            {
              params: {
                id: id,
              },
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.index()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    padding: 15px 1px;
  }
  .int_width {
    width: 242px;
    margin-right: 100px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
}
</style>
