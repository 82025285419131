<template>
  <div>
    <el-breadcrumb style="margin:0 0 6px 6px;" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>人员管理</el-breadcrumb-item>
      <el-breadcrumb-item>人员列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">人员列表</div>
        <div>
          <el-button size="mini" type="cha" icon="el-icon-search" @click="chaxun">查询</el-button>
          <el-button type="add"  size="mini" icon="el-icon-circle-plus-outline" @click="add">添加</el-button>
        </div>
      </div>
      <div class="int_box">
         <div class="int_box_a">
           <span>姓名：</span>
        <el-input size="small" class="int_width" placeholder="请输入姓名" v-model="name" clearable></el-input>
        </div>
        <div class="int_box_a">
           <span>邮箱：</span>
        <el-input size="small" class="int_width" placeholder="请输入邮箱" v-model="email" clearable></el-input>
        </div>
        <div class="int_box_a">
           <span>手机号：</span>
        <el-input size="small" class="int_width" placeholder="请输入手机号" v-model="mobile" clearable></el-input>
        </div>
        <div class="int_box_a">
          <span>是否锁定：</span>
        <el-radio-group v-model="lock">
          <el-radio :label="'-1'">全部</el-radio>
          <el-radio :label="'0'">否</el-radio>
          <el-radio :label="'1'">是</el-radio>
        </el-radio-group>
        </div>
        
      </div>
      <template>
        <el-table :header-cell-style="tableHeader" :data="indexList" style="width: 100%" stripe>
          <el-table-column prop="id" label="ID号"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="nickname" label="姓名"></el-table-column>
          <el-table-column min-width="160px" prop="groups_text" label="所属角色"></el-table-column>
          <el-table-column min-width="120px" prop="email" label="邮箱"></el-table-column>
          
          <el-table-column label="是否锁定">
            <template v-slot="scope">
              <div type="text">{{scope.row.locked == 0 ? '正常' : '锁定'}}</div>
            </template>
          </el-table-column>
          <el-table-column width="130px" label="操作">
            <template v-slot="scope">
              <el-tooltip :enterable="false" effect="dark" content="编辑" placement="top-end">
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                size="mini"
                type="danger"
                icon="iconfont icon-shanchu"
                @click="del(scope.row.id, scope.$index)"
              ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <el-form ref="form" :model="addform" :rules="rules" label-width="140px">
          <el-form-item label="用户名：" prop="username">
            <el-input size="small"
              :disabled="id ? true : false"
              style="width:450px;"
              v-model="addform.username"
              placeholder="请输入用户名"
              clearable
            ></el-input>
            <span v-if="!id" style="color:#ccc;margin-left: 30px;">注：添加后不可修改</span>
          </el-form-item>
          <el-form-item label="所属角色：" prop="group_id">
            <el-cascader size="small" style="width:450px;" v-model="addform.group_id" :props="props" :options="groupsList"></el-cascader>
          </el-form-item>
          <el-form-item label="姓名：" prop="name">
            <el-input size="small" style="width:450px;" v-model="addform.name" placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input size="small" style="width:450px;" v-model="addform.mobile" placeholder="请输入手机号" clearable></el-input>
          </el-form-item>
          <el-form-item v-if="id" label="密码：">
            <el-input size="small"
              show-password
              style="width:450px;"
              v-model="addform.password"
              placeholder="请输入密码"
              clearable
            ></el-input>
            <span style="color:#ccc;margin-left: 30px;">注：不填默认不更改密码</span>
          </el-form-item>
          <el-form-item v-else label="密码：" prop="password">
            <el-input size="small"
              show-password
              style="width:450px;"
              v-model="addform.password"
              placeholder="请输入密码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input size="small" style="width:450px;" v-model="addform.email" placeholder="请输入邮箱" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否锁定：">
            <el-radio-group v-model="addform.locked">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn">确定</el-button>
        <el-button v-else type="cha" class="add_btn" @click="add_btn">确定</el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3456789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    var checkEmail = (rule, value, cb) => {
      const regMobile = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      page: 1,
      limit:10,
      name: '',
      email: '',
      mobile: '',
      lock: '-1',

      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333'
      },

      dialogVisible: false,
      addform: {
        username: '',
        email: '',
        name: '',
        password: '',
        mobile: '',
        group_id: [],
        locked: 0
      },

      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
        ],
        group_id: [
          { required: true, message: '请选择所属角色', trigger: 'blur' }
          
        ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' },
         { validator: checkEmail, trigger: 'blur' }],
        locked: [{ required: true, message: '请选择是否锁定', trigger: 'blur' }]
      },
      groupsList: [],
      props: {
        value: 'id',
        label: 'name',
        multiple: true,
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      text: '',
      id: '',
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.id = ''
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform= {
        username: '',
        email: '',
        name: '',
        password: '',
        mobile: '',
        group_id: [],
        locked: 0,
        id: ''
      }
      
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 管理员列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/auth/admin/index', {
        params: {
          page: this.page,
          limit: this.limit,
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          lock: this.lock
        }
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.index()
    },
   
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.groups()
      this.__token__()
    },
    // 权限组
    async groups() {
      const { data: res } = await this.$http.get('edulogin.php/auth/group/index')
      if (res.code != 1) return this.$message.error(res.msg)
      this.groupsList = res.data.list
    },
    // 添加确认
    add_btn() {
      this.$refs.form.validate(async valid => {
        if (!valid) return this.$message.error('请填写完整的数据')

        // this.addform.__token__ = this.token
        // this.addform.group_id = this.addform.group_id.join()
        const { data: res } = await this.$http.post(
          'edulogin.php/auth/admin/add',
          {
            username: this.addform.username,
            email: this.addform.email,
            name: this.addform.name,
            password: this.addform.password,
            mobile: this.addform.mobile,
            group_id: this.addform.group_id.join(),
            locked: this.addform.locked,
            __token__: this.token
          }
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.addform = {
         username: '',
          email: '',
          name: '',
          password: '',
          mobile: '',
          group_id: [],
          locked: 0
      },
        this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.isdisabled = true
      this.text = '编辑'
      this.id = row.id
      this.dialogVisible = true
      this.addform = {
        username: row.username,
        email: row.email,
        name: row.nickname,
        password: '',
        mobile: row.mobile,
        group_id: row.groups.split(','),
        locked: row.locked,
        type: row.user_type,
        id: row.id
      }

      this.__token__()
      this.groups()
    },
    // 编辑确认 
    async edit_btn() {
      // this.addform.__token__ = this.token
      // this.addform.group_id = this.addform.group_id.join()
      const { data: res } = await this.$http.post('edulogin.php/auth/admin/edit',{
        username: this.addform.username,
        email: this.addform.email,
        name: this.addform.name,
        password: this.addform.password,
        mobile: this.addform.mobile,
        group_id: this.addform.group_id.join(),
        locked: this.addform.locked,
        // type: this.addform.type,
        __token__: this.token,
        id: this.id
      })
      if(res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        username: '',
        email: '',
        name: '',
        password: '',
        mobile: '',
        group_id: [],
        locked: 0,
        // type: 4,
        id: ''
      },
      this.id = ''
      this.index()

    },
    // 删除
    del(id, i) {
       this.$confirm('此操作将删除该人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.get('edulogin.php/auth/admin/delinfo',      
              {
              params: {
                id: id,
              },
            }      
          )
          if (res.code !== 1) return this.$message.error(res.msg)
          this.indexList.splice(i, 1)
          this.$message.success(res.msg)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
       
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
}
</style>
